<script setup>
const route = useRoute()
const btnMsg = ref(`Back to Home`)
const errMsg = ref(`But don't worry, you can find plenty of other information on our homepage.`)

const handleError = () => {
  if (route.fullPath.includes('/client')) {
    clearError({ redirect: '/client' })
  } else {
    clearError({ redirect: '/' })
  }
}

onMounted(() => {
  if (route.fullPath.includes('/client')) {
    btnMsg.value = `Back to Dashboard`
    errMsg.value = `Return to your Dashboard and try again. If you feel this is an error, please reach out to our client care team.`
  }
})
</script>

<template>
  <section class="flex items-center justify-center h-screen p-16">
    <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
      <div class="max-w-md text-center">
        <h2 class="mb-8 font-extrabold text-9xl text-slate-300">
          <span class="sr-only">Error</span>404
        </h2>
        <p class="text-2xl font-semibold md:text-5xl">Sorry, we couldn't find this page.</p>
        <p class="mt-4 mb-8">{{ errMsg }}</p>
        <button class="px-8 py-3 font-semibold rounded bg-slate-800 text-white" @click="handleError">{{ btnMsg }}</button>
      </div>
    </div>
  </section>
</template>
